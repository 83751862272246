const getSiteObject = function (contentfulSiteConfiguration) {    
    var site = {
        siteMetadata : {
            title           : contentfulSiteConfiguration.siteName,
            description     : "",
            author          : "",
            siteUrl         : contentfulSiteConfiguration.websiteUrl,
            canonical       : contentfulSiteConfiguration.websiteUrl,
            siteLanguage    : "en-US",
            copyright       : contentfulSiteConfiguration.copyright,
            contact : {
                phone   : contentfulSiteConfiguration.contactPhoneNumber,
                email   : contentfulSiteConfiguration.contactEmailAddress,
                address : contentfulSiteConfiguration.contactOfficeAddress,
                website : contentfulSiteConfiguration.websiteUrl
            },
            social : {
                facebook    : contentfulSiteConfiguration.facebookUrl,
                twitter     : "",
                instagram   : "",
                linkedin    : ""
            }            
        }
    }

    return site;
};

const getHeaderMenu = function(contentfulSiteConfiguration) { 

    var menu = {
        edges :  contentfulSiteConfiguration.headerNavigationMenu.items.map(item => {
            var node = {
                node : {
                    id   : item.id,
                    text : item.text,
                    link : getPageLink(item),
                }
            }
    
            return node;
        })
    }
   
    return menu;
}

const getPageLink = function(navigationMenuItem){

    if(navigationMenuItem.useCustomLink) {
        return navigationMenuItem.customLink;
    }

    if(navigationMenuItem.page.sys.contentType.sys.id == "solutionPage") {
        return "/solutions/" + navigationMenuItem.page.urlCode;
    }

    return "/";
}

const getFooterMenu = function(contentfulSiteConfiguration) { 

    var menu = {
        edges :  contentfulSiteConfiguration.footerNavigationMenus.map(navigationMenu => {
            return {
                node : {
                    id   : navigationMenu.id,
                    text : navigationMenu.name,
                    link : "",
                    submenu : navigationMenu.items.map(item => {
                        return {
                            id   : item.id,
                            text : item.text,
                            link : getPageLink(item)
                        }            
                    })
                }
            };
        })
    }
   
    return menu;
}

module.exports = { getSiteObject, getHeaderMenu, getFooterMenu }