import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Layout from '../../../shared-ui/layouts/standard'
import LightLogo from '../assets/images/logo/light-logo.png'
import DarkLogo from '../assets/images/logo/logo-cuatrotorres-v2.png'
import { getSiteObject, getHeaderMenu, getFooterMenu } from '../utils/contentfulMapHelper'

const CustomLayout = ({children, location}) => {  

  const { siteConfigurationList, allHeadermenuJson, allFootermenuJson, allHeadlineJson, site } = useStaticQuery(graphql`
        query GET_LANDING_MENU {
          siteConfigurationList : allContentfulSiteConfiguration (limit: 1) {
            edges {
              node {
                id
                siteName
                siteLogo {                  
                  fluid(maxWidth: 300, quality: 100) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyContentfulFluid
                  }
                }
                facebookUrl
                contactOfficeAddress 
                contactEmailAddress
                contactPhoneNumber
                websiteUrl
                copyrightName
                headerNavigationMenu {
                  id
                  name
                  items {
                    id
                    text
                    page {
                      sys {
                        contentType {
                          sys {                            
                            id
                          }
                        }                       
                      }
                      urlCode
                    }
                  }
                }
                footerNavigationMenus {
                  id
                  name
                  items {
                    id
                    text
                    page {
                      sys {
                        contentType {
                          sys {                            
                            id
                          }
                        }                        
                      }
                      urlCode
                    }
                  }
                }
              }
            }
          }

            allHeadermenuJson {
                edges {
                    node {
                        text
                        link
                        id                        
                    }
                }
            }
            allFootermenuJson {
              edges {
                  node {
                      text
                      link
                      id
                      submenu {
                        link
                        text
                      }
                  }
              }
          }
          allHeadlineJson {
              edges {
                node {
                  isVisible
                  introText
                  text
                  link
              }
            }
          }
          site {
              siteMetadata {
                  title
                  description
                  author
                  siteUrl
                  canonical
                  siteLanguage
                  image
                  titleTemplate
                  twitterUsername

                  copyright
                    contact {
                        phone
                        email
                        address
                        website
                    }
                    social {
                        facebook
                        twitter
                        instagram
                        linkedin
                    }
              }
              buildTime
            }
        } 
    `);

  return (
    <Layout 
        location={location} 
        menuData={getHeaderMenu(siteConfigurationList.edges[0].node)} 
        footerMenu={getFooterMenu(siteConfigurationList.edges[0].node)} 
        headline={allHeadlineJson.edges[0].node} 
        site={getSiteObject(siteConfigurationList.edges[0].node)} 
        lightLogo={siteConfigurationList.edges[0].node.siteLogo.fluid.src} 
        darkLogo={siteConfigurationList.edges[0].node.siteLogo.fluid.src} >           
      {children}
    </Layout>)
}
 
CustomLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
  
export default CustomLayout